import { ShowInstance } from "@open-mic-site/models/dist/schemas";
import { FormEvent, useCallback, useMemo, useState } from "react";
import { useValidate } from "../hooks/useValidate";
import { post } from "../utils/api";
import { Button } from "@mui/material";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import type {} from "@mui/x-date-pickers/themeAugmentation";

import dayjs from "dayjs";
import { Stack } from "@mui/system";

interface Props {
  showId: string;
}

export function CreateInstanceForm({ showId }: Props) {
  const [startTime, setStartTime] = useState<Date>(new Date());
  const [endTime, setEndTime] = useState<Date>(new Date());
  const values = useMemo(
    () => ({
      show: showId,
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
    }),
    [endTime, showId, startTime]
  );
  const { validate, validationErrors, isValidating } = useValidate(
    ShowInstance.omit(["overrides"]),
    values
  );

  const handleSubmit = useCallback(
    async (submitEvent: FormEvent) => {
      submitEvent.preventDefault();

      const { isValid } = await validate(() => post("/showinstance", values));

      if (!isValid) {
        return;
      }
    },
    [validate, values]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form onSubmit={handleSubmit}>
        <fieldset
          style={{
            margin: "16px 0",
            backgroundColor: "rgba(0,0,0,0.01)",
            padding: 16,
            border: "1px #ccc solid",
            borderRadius: 4,
          }}
        >
          <h3 style={{ marginBottom: 8 }}>Add date</h3>

          <Stack spacing={2}>
            <MobileDateTimePicker
              value={dayjs(startTime)}
              onChange={(value) => setStartTime(value?.toDate() ?? new Date())}
              label="Start time"
              aria-errormessage={validationErrors.startTime}
            />
            <MobileDateTimePicker
              value={dayjs(endTime)}
              onChange={(value) => setEndTime(value?.toDate() ?? new Date())}
              label="End time"
              aria-errormessage={validationErrors.endTime}
              minTime={dayjs(startTime)}
            />
            <Button
              type="submit"
              data-loading={isValidating}
              disabled={isValidating}
              variant="contained"
              size="large"
            >
              Add
            </Button>
          </Stack>
        </fieldset>
      </form>
    </LocalizationProvider>
  );
}
