import { Button, TextField } from "@mui/material";

interface Props {
  values: string[];
  onValuesChange: (values: string[]) => void;
  placeholder?: string;
}

export function ListInput({ values, onValuesChange, placeholder }: Props) {
  return (
    <ol style={{ margin: 0, padding: 0, gap: 16, display: 'flex', flexDirection: 'column' }}>
      {values.map((value, index) => (
        <li
          key={index}
          style={{
            listStyleType: "decimal",
            margin: "0 0 0 24px",
            padding: "0 0 0 8px",
          }}
        >
          <TextField
            type="text"
            value={value}
            onChange={({ target: { value: newValue } }) =>
              onValuesChange(
                values.map((value, i) => (i === index ? newValue : value))
              )
            }
            placeholder={placeholder}
            fullWidth
          />
        </li>
      ))}
      <Button
        onClick={() => onValuesChange([...values, ""])}
        disabled={values.some((value) => !Boolean(value))}
        fullWidth
      >
        +
      </Button>
    </ol>
  );
}
