import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export function H3({ children }: Props) {
  return (
    <h3
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 16,
      }}
    >
      <span
        style={{
          color: "#777",
          fontSize: "1.4rem",
          fontWeight: '400',
          lineHeight: "1.6rem",
        }}
      >
        {children}
      </span>
    </h3>
  );
}
