import { useCallback } from "react";
import { API_URL } from "../config/api";
import { getItem } from "../state/helpers/storage";

export const useUploadImage = () => {
  const uploadImage = useCallback(
    async (file: File) => {
      const apiUrl = `${API_URL}/imageupload`;
      const token = getItem("auth.token");

      const form = new FormData();
      form.append("file", file);

      return await fetch(apiUrl, {
        method: "POST",
        body: form,
        mode: "cors",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((r) => r.json());
    },
    []
  );

  return uploadImage;
};
