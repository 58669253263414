import { useCallback } from "react";

const fileInput = document.createElement("input");
fileInput.setAttribute("type", "file");

export interface FileDialogOptions {
  imagesOnly?: boolean;
  multiple?: boolean;
}

export const useFileDialog = () => {
  const chooseFiles = useCallback(
    async ({ imagesOnly = true, multiple = false }: FileDialogOptions = {}) => {
      if (imagesOnly) {
        fileInput.setAttribute(
          "accept",
          "image/jpeg, image/png, image/gif, image/bmp, image/heic, image/heif"
        );
      } else {
        fileInput.removeAttribute("accept");
      }

      if (multiple) {
        fileInput.setAttribute("multiple", "multiple");
      }

      fileInput.click();

      return await new Promise<FileList | File[]>((resolve, reject) => {
        const handleChange = async function (this: { files: FileList }) {
          const { files } = this;

          fileInput.removeEventListener("change", handleChange);

          if (files && files.length) {
            if (!imagesOnly) {
              return resolve(files);
            }

            const compressedFiles: File[] = [];

            for (let i = 0; i < files.length; i++) {
              const file = files.item(i);

              if (!file) {
                continue;
              }

              let convertedFile = file;

              if (["image/heic", "image/heif"].includes(file.type)) {
                try {
                  const { default: convertHeic } = await import("heic2any");
                  const blob = await convertHeic({
                    blob: file,
                    toType: "image/jpeg",
                  });

                  convertedFile = new File(
                    [blob as BlobPart],
                    file.name?.replace(/\.(heic)|(heif)$/gi, ".jpg"),
                    { type: "image/jpeg" }
                  );
                } catch (ex) {
                  console.error(ex);
                  //alert(i18next.t<string>("private_image_heic_error"));
                }
              }

              if (file.size < 1024 * 1024 || file.type !== "image/jpeg") {
                compressedFiles.push(convertedFile);
                continue;
              }

              const { default: imageCompression } = await import(
                "browser-image-compression"
              );

              compressedFiles.push(
                await imageCompression(convertedFile, {
                  maxSizeMB: 2,
                  maxWidthOrHeight: 1920,
                  useWebWorker: true,
                })
              );
            }

            return resolve(compressedFiles);
          }

          reject();
        };
        fileInput.addEventListener("change", handleChange);
        fileInput.addEventListener("blur", handleChange);
      });
    },
    []
  );

  return chooseFiles;
};
