import { TextField } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { post } from "../utils/api";

interface Props {
  eventUrl: string;
  onChangeEventUrl: (eventUrl: string) => void;
  onRetrievedEvent: (
    event: Awaited<ReturnType<typeof post<"/extscrapeevent">>>["event"]
  ) => void;
}

export function EventUrlInput({
  eventUrl,
  onChangeEventUrl,
  onRetrievedEvent,
}: Props) {
  const scrapeUrl = useCallback(
    async (url: string) => {
      try {
        const { event } = await post("/extscrapeevent", { url });

        if (event) {
          onRetrievedEvent(event);
        }
      } catch {}
    },
    [onRetrievedEvent]
  );
  const scrapeUrlDebounced = useDebouncedCallback(scrapeUrl, 1000);

  useEffect(() => {
    if (eventUrl) {
      scrapeUrlDebounced(eventUrl);
    }
  }, [eventUrl, scrapeUrlDebounced]);

  return (
    <TextField
      type="url"
      label="Ticket link"
      placeholder="https://eventbrite.com/..."
      value={eventUrl}
      onChange={({ target: { value } }) => onChangeEventUrl(value)}
      fullWidth
    />
  );
}
