declare global {
  interface Window {
    __state: Record<string, string>;
  }
}

window.__state = {};

type STORAGE_KEY = "auth.token" | "auth.userId";

export const getItem = (key: STORAGE_KEY): string | null => {
  if (typeof window.__state[key] === "string") {
    return window.__state[key];
  }

  try {
    return localStorage.getItem(key) ?? null;
  } catch (ex) {
    console.error(ex);
    return null;
  }
};

export const setItem = (key: STORAGE_KEY, value: string) => {
  window.__state[key] = value;

  try {
    localStorage.setItem(key, value);
  } catch (ex) {
    console.error(ex);
  }
};
