import { Route, Routes } from "react-router-dom";
import { Auth, EditShow, SignUp } from "./pages";
import { ListShows } from "./pages/ListShows";
import { useAuth } from "./state";

export function App() {
  const { token } = useAuth();

  return (
    <Routes>
      {!Boolean(token) && (
        <>
          <Route path="/auth" element={<Auth />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="*" element={<SignUp />} />
        </>
      )}

      {Boolean(token) && (
        <>
          <Route path="/show/list" element={<ListShows />} />
          <Route path="/show/create" element={<EditShow />} />
          <Route path="/show/edit/:id" element={<EditShow />} />
          <Route path="*" element={<ListShows />} />
        </>
      )}
    </Routes>
  );
}
