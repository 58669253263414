import type { Responses } from "@open-mic-site/api";
import { useCallback, useEffect, useState } from "react";
import { get } from "../utils/api";

type ApiUrl<T extends keyof Responses> = Parameters<typeof get<T>>[0];
type ApiParams<T extends keyof Responses> = Parameters<typeof get<T>>[1];

const isError = (error: unknown): error is Error => error instanceof Error;

export function useGet<T extends keyof Responses>(
  url: ApiUrl<T>,
  params?: ApiParams<T>
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<Responses[T]["get"] | null>(null);

  const doFetch = useCallback(async () => {
    setLoading(true);

    try {
      setData(await get(url, params));
    } catch (error: unknown) {
      if (isError(error)) {
        setError(error);
      } else {
        setError(new Error(`Could not get ${url}`));
      }
    }

    setLoading(false);
  }, [url, params]);

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  return {
    loading,
    error,
    data,
  };
}
