import { Stack, TextField, Button } from "@mui/material";
import { FormEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { H1 } from "../components";
import { useAuth } from "../state";
import { post } from "../utils/api";

export function Auth() {
  const [userNameEmailOrPhone, setUserNameEmailOrPhone] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");

  const { setToken, setUserId, userId } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (submitEvent: FormEvent) => {
      submitEvent.preventDefault();

      if (userId) {
        const response = await post("/authconfirm", {
          code: confirmationCode,
          user: userId,
        });
        setToken(response.token);
        return;
      }

      try {
        const response = await post("/authrequest", {
          id: userNameEmailOrPhone,
        });

        if (typeof response?.user === "string") {
          setUserId(response?.user);
        }
      } catch (ex) {
        if (ex instanceof Error && ex.message === "NOT_FOUND") {
          navigate("/signup");
        }
      }
    },
    [
      confirmationCode,
      navigate,
      setToken,
      setUserId,
      userId,
      userNameEmailOrPhone,
    ]
  );

  return (
    <main>
      <article style={{ maxWidth: 600, margin: "0 auto", padding: "0 16px" }}>
        <form method="post" onSubmit={handleSubmit}>
          <H1>Authenticate</H1>

          <Stack spacing={2}>
            {userId ? (
              <TextField
                type="digit"
                aria-label="Confirmation code"
                placeholder="XXXXXX"
                value={confirmationCode}
                onChange={({ target: { value } }) => setConfirmationCode(value)}
              />
            ) : (
              <TextField
                type="text"
                aria-label="Username, email or phone"
                placeholder="someone@example.org"
                value={userNameEmailOrPhone}
                onChange={({ target: { value } }) =>
                  setUserNameEmailOrPhone(value)
                }
              />
            )}
            <Button type="submit" variant="contained" size="large">
              Auth
            </Button>
          </Stack>
        </form>
      </article>
    </main>
  );
}
