import { formatInTimeZone } from "date-fns-tz";
import { Link } from "react-router-dom";
import { IconPlus, IconPencil, IconEye } from "@tabler/icons-react";
import { H1, H2, H3 } from "../components";
import { CreateInstanceForm } from "../components/CreateInstanceForm";
import { useGet } from "../hooks/useGet";
import { DOMAIN_STOREFRONT } from "../config/domains";

// Todo: timezones

export const ListShows = () => {
  const { data } = useGet("/show");

  if (!data?.shows) {
    return null;
  }

  const { shows, users } = data;

  return (
    <article style={{ maxWidth: 600, margin: "0 auto", padding: "0 16px" }}>
      <H1>My shows</H1>
      <Link
        to="/show/create"
        style={{
          position: "fixed",
          bottom: 32,
          left: "50%",
          borderRadius: "50%",
          backgroundColor: "#333",
          color: "#fff",
          zIndex: 1,
          boxShadow: "#333 4px 4px 18px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 60,
          width: 60,
        }}
        title="Create show"
      >
        <IconPlus size={32} />
      </Link>

      {shows.map((show) => (
        <section key={show.id} style={{ marginBottom: 32 }}>
          <H2>{show.title}</H2>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: 16 }}>
            <Link
              to={`/show/edit/${show.id}`}
              style={{
                padding: "2px 8px",
                border: "1px #999 solid",
                borderRadius: 2,
                color: "#999",
                display: "flex",
                alignItems: "center",
                gap: 4,
              }}
            >
              <IconPencil />
              <span>Edit show</span>
            </Link>
            <Link
              to={`//${DOMAIN_STOREFRONT}/${show.id}`}
              style={{
                padding: "2px 8px",
                border: "1px #999 solid",
                borderRadius: 2,
                color: "#999",
                display: "flex",
                alignItems: "center",
                gap: 4,
              }}
              target="_blank"
              rel="noreferrer noopener"
            >
              <IconEye />
              <span>Preview</span>
            </Link>
          </div>

          {show.instances.map((instance) => (
            <div
              style={{
                borderLeft: "4px #666 solid",
                padding: 16,
                marginBottom: 16,
              }}
            >
              <H3>
                {formatInTimeZone(
                  new Date(instance.startTime!),
                  "Europe/London",
                  "EEEE, do HH:mm"
                )}
              </H3>
              <dl>
                <dt>Available spots</dt>
                <dd>
                  {(show.lineupSettings?.defaultAmountOfSpots ?? 0) -
                    (instance.lineUp.performers ?? []).length}
                </dd>
                <dt>Line up</dt>
                <dd>
                  <div>
                    {(instance.lineUp.performers ?? []).map((performerId) => {
                      const performer = users.find(
                        ({ id }) => id === performerId
                      );

                      if (!performer) {
                        return null;
                      }

                      return <div>{performer.name}</div>;
                    })}
                  </div>
                </dd>
              </dl>
            </div>
          ))}

          <CreateInstanceForm showId={show.id} />
        </section>
      ))}
    </article>
  );
};
