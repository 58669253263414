import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export function H2({ children }: Props) {
  return (
    <h2
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 24,
      }}
    >
      <span
        style={{
          color: "#666",
          fontSize: "2rem",
          fontWeight: '600',
          lineHeight: "2rem",
          padding: 4
        }}
      >
        {children}
      </span>
    </h2>
  );
}
