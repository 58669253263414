import { useCallback, useState } from "react";
import type * as yup from "yup";

// Todo: why is Partial necessary here?
export function useValidate<Schema extends yup.Schema>(
  schema: Schema,
  values: Partial<yup.InferType<Schema>>
) {
  const [isValidating, setIsValidating] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});

  const validate = useCallback(
    async <ApiResponse = boolean>(
      apiCall?: () => Promise<ApiResponse>
    ): Promise<
      typeof apiCall extends undefined
        ? boolean
        :
            | { isValid: true; response: ApiResponse }
            | { isValid: false; response: null }
    > => {
      setIsValidating(true);

      try {
        await schema.validate(values, { abortEarly: false });

        if (apiCall) {
          const response = await apiCall();
          setValidationErrors({});
          setIsValidating(false);
          return { isValid: true, response };
        }

        setValidationErrors({});
        setIsValidating(false);
        return true as unknown as { isValid: true; response: ApiResponse };
      } catch (ex: any) {
        console.log(ex);

        if (ex?.name === "ValidationError") {
          try {
            if (ex.message && typeof ex.message === "object") {
              setValidationErrors(ex.message);
            } else {
              const validationErrors = (
                ex.inner.length ? ex.inner : ex.errors
              ).reduce(
                (
                  prev: Record<string, string>,
                  curr: { path: string; message: string }
                ) => {
                  prev[curr.path] = curr.message;
                  return prev;
                },
                {}
              );
              setValidationErrors(validationErrors);
            }
          } catch (ex) {
            console.log(ex);
          }
        }
      }

      setIsValidating(false);

      if (apiCall) {
        return { isValid: false, response: null };
      }
      return false as unknown as { isValid: false; response: null };
    },
    [schema, values]
  );

  return { validate, validationErrors, isValidating };
}
