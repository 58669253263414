"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.schema = void 0;
const yup = __importStar(require("yup"));
const cuid2_1 = require("@paralleldrive/cuid2");
const location_1 = require("../schema-mixins/location");
exports.schema = yup.object({
    admins: yup.array(yup.string().required().test(cuid2_1.isCuid)).required(),
    createdBy: yup.string().required().test(cuid2_1.isCuid),
    title: yup.string().required().min(3).max(100),
    description: yup.string().required().min(3).max(500),
    remoteUrl: yup.string().trim().lowercase().url().nullable().default(null),
    location: location_1.LOCATION_SCHEMA.required(),
    lineupSettings: yup
        .object({
        defaultAmountOfSpots: yup.number().required().default(8),
        defaultLengthOfSpot: yup
            .number()
            .required()
            .default(5 * 60 * 1000),
    })
        .required(),
    rules: yup.array(yup.string().required().min(1).max(200)),
    coverImage: yup.string().nullable().default(null),
    logo: yup.string().nullable().default(null),
});
