import { Button, Stack, TextField } from "@mui/material";
import { Show } from "@open-mic-site/models/dist/schemas";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { H1, LocationInput } from "../components";
import { EventUrlInput } from "../components/EventUrlInput";
import { ListInput } from "../components/ListInput";
import { useFileDialog } from "../hooks/useFileUpload";
import { useGet } from "../hooks/useGet";
import { useUploadImage } from "../hooks/useUploadImage";
import { useValidate } from "../hooks/useValidate";
import { post } from "../utils/api";

type Location = Parameters<typeof post<"/show">>[1]["location"];

const stripHtml = (content: string) => {
  const doc = new DOMParser().parseFromString(content, "text/html");
  return doc.body.textContent || "";
};

export const EditShow = () => {
  const openFileDialog = useFileDialog();
  const uploadImage = useUploadImage();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [amountOfSpots, setAmountOfSpots] = useState(8);
  const [lengthOfSpots, setLengthOfSpots] = useState(7);
  const [remoteUrl, setRemoteUrl] = useState("");
  const [rules, setRules] = useState<string[]>([""]);
  const [location, setLocation] = useState<Location>();
  const [coverImage, setCoverImage] = useState("");
  const [logo, setLogo] = useState("");
  const params = useParams();
  const { data } = useGet("/show");

  const show = useMemo(
    () => data?.shows?.find((show) => show.id === params?.id),
    [data?.shows, params?.id]
  );

  const values = useMemo(
    () => ({
      title,
      description,
      lineupSettings: {
        defaultAmountOfSpots: amountOfSpots,
        defaultLengthOfSpot: lengthOfSpots,
      },
      remoteUrl,
      rules: rules.filter(Boolean),
      location,
      coverImage,
      logo,
      ...(params?.id ? { id: params.id } : {}),
    }),
    [
      title,
      description,
      amountOfSpots,
      lengthOfSpots,
      remoteUrl,
      rules,
      location,
      coverImage,
      logo,
      params.id,
    ]
  );

  const navigateTo = useNavigate();
  const { validate, validationErrors, isValidating } = useValidate(
    Show.omit(["admins", "createdBy"]),
    values
  );

  const handleSubmit = useCallback(
    async (submitEvent: FormEvent) => {
      submitEvent.preventDefault();

      const { isValid } = await validate(() => post("/show", values));

      if (!isValid) {
        return;
      }

      navigateTo("/show/list");
    },
    [navigateTo, validate, values]
  );

  const handleUploadFile = useCallback(
    (key: "coverImage" | "logo") => async () => {
      try {
        const file = (
          await openFileDialog({ imagesOnly: true, multiple: false })
        )?.[0];
        const { filePath } = await uploadImage(file);

        if (key === "coverImage") {
          setCoverImage(filePath);
        } else {
          setLogo(filePath);
        }
      } catch {}
    },
    [openFileDialog, uploadImage]
  );

  useEffect(() => {
    if (show) {
      setTitle(show.title ?? "");
      setDescription(show.description ?? "");
      setAmountOfSpots(show.lineupSettings?.defaultAmountOfSpots ?? 8);
      setLengthOfSpots(show.lineupSettings?.defaultLengthOfSpot ?? 7);
      setRemoteUrl(show.remoteUrl ?? "");
      setRules(show.rules ?? []);
      setLocation(show.location ?? {});
      setCoverImage(show.coverImage ?? "");
      setLogo(show.logo ?? "");
    }
  }, [show]);

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <article style={{ maxWidth: 600, margin: "0 auto", padding: "0 16px" }}>
        <H1>Create new show</H1>

        <h2>Name &amp; Logo</h2>
        <section>
          <Stack spacing={2}>
            <EventUrlInput
              eventUrl={remoteUrl}
              onChangeEventUrl={setRemoteUrl}
              onRetrievedEvent={(event) => {
                if (!event) {
                  return;
                }

                if (!title && event.title) {
                  setTitle(event.title);
                }

                if (!description && event.description) {
                  setDescription(stripHtml(event.description).slice(0, 250));
                }
              }}
            />
            <TextField
              type="text"
              label="Show name"
              value={title}
              onChange={({ target: { value } }) => setTitle(value)}
              error={Boolean(validationErrors["title"])}
              helperText={validationErrors["title"]}
              fullWidth
            />
            <Button type="button" onClick={handleUploadFile("logo")}>
              Upload Logo
            </Button>
            <Button type="button" onClick={handleUploadFile("coverImage")}>
              Upload cover image
            </Button>
          </Stack>
        </section>

        <h2>About the show</h2>
        <section>
          <TextField
            label="Show description"
            value={description}
            onChange={({ target: { value } }) => setDescription(value)}
            error={Boolean(validationErrors["description"])}
            helperText={validationErrors["description"]}
            multiline
            rows={5}
            fullWidth
          />
        </section>

        <h2>Location</h2>
        <section>
          <LocationInput location={location} onChangeLocation={setLocation} />
        </section>

        <h2>Lineup</h2>
        <section>
          <Stack spacing={2}>
            <TextField
              type="number"
              label="Open spots per show"
              value={String(amountOfSpots)}
              onChange={({ target: { value } }) =>
                setAmountOfSpots(Number(value))
              }
              fullWidth
            />
            <TextField
              type="number"
              label="Time of performance"
              value={String(lengthOfSpots)}
              onChange={({ target: { value } }) =>
                setLengthOfSpots(Number(value))
              }
              fullWidth
            />
          </Stack>
        </section>

        <h2>Conditions</h2>
        <section>
          <ListInput
            values={rules}
            onValuesChange={setRules}
            placeholder="Do not pee on stage"
          />
        </section>

        <div style={{ margin: "16px 0" }}>
          <Button
            color="primary"
            type="submit"
            data-loading={isValidating}
            variant="contained"
            size="large"
            fullWidth
          >
            {params?.id ? "Save changes" : "Create show"}
          </Button>
        </div>
      </article>
    </form>
  );
};
