import type { Requests, Responses } from "@open-mic-site/api";
import { ValidationError } from "yup";
import { API_URL } from "../config/api";
import { getItem } from "../state/helpers/storage";

export const hasStatus = (input: any): input is { status: string } => {
  return typeof input.status === "string";
};

const getHeaders = () => {
  const headers: HeadersInit = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const token = getItem("auth.token");

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return headers;
};

type RouteHasGet<T extends keyof Responses> = Responses[T] extends {
  get: never;
}
  ? never
  : T;
export const get = async <T extends keyof Responses>(
  url: RouteHasGet<T>,
  data?: Requests[T]["get"]
) => {
  const searchParams = new URLSearchParams(data ?? {});
  const response = await fetch(`${API_URL}${url}?${searchParams.toString()}`, {
    method: "get",
    headers: getHeaders(),
  });
  const responseBody = await response.json();
  return responseBody as Responses[T] extends { get: Record<string, unknown> }
    ? Responses[T]["get"]
    : never;
};

export const post = async <T extends keyof Responses>(
  url: T,
  data: Requests[T]["post"]
) => {
  const response = await fetch(`${API_URL}${url}`, {
    method: "post",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  const responseBody = await response.json();

  if (response.status === 404) {
    throw new Error("NOT_FOUND");
  }

  if (response.status === 409 && responseBody?.validationErrors) {
    throw new ValidationError(responseBody.validationErrors);
  }

  if (response.status >= 400) {
    throw new Error("UNKNOWN_ERROR");
  }

  return responseBody as Responses[T]["post"];
};
