import { Button, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { User } from "@open-mic-site/models/dist/schemas";
import { FormEvent, useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { H1 } from "../components";
import { useValidate } from "../hooks/useValidate";
import { useAuth } from "../state";
import { post } from "../utils/api";

export function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userName, setUserName] = useState("");

  const { setUserId } = useAuth();

  const values = useMemo(
    () => ({
      email,
      phone,
      name,
      userName,
    }),
    [email, name, phone, userName]
  );

  const navigate = useNavigate();
  const { validate, validationErrors, isValidating } = useValidate(
    User,
    values
  );

  const handleSubmit = useCallback(
    async (submitEvent: FormEvent) => {
      submitEvent.preventDefault();

      const { isValid, response } = await validate(() =>
        post("/authsignup", values)
      );

      if (!isValid || !response) {
        return;
      }
      setUserId(response.userId);
      navigate("/auth");
    },
    [navigate, setUserId, validate, values]
  );

  return (
    <main>
      <article style={{ maxWidth: 600, margin: "0 auto", padding: "0 16px" }}>
        <form method="post" onSubmit={handleSubmit}>
          <H1>Sign Up</H1>

          <Stack spacing={2}>
            <TextField
              type="email"
              label="Email"
              placeholder="someone@example.org"
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
              helperText={validationErrors["email"]}
              error={Boolean(validationErrors["email"])}
              fullWidth
            />
            <TextField
              type="phone"
              label="Phone"
              placeholder="+44 XXX ..."
              value={phone}
              onChange={({ target: { value } }) => setPhone(value)}
              helperText={validationErrors["phone"]}
              error={Boolean(validationErrors["phone"])}
              fullWidth
            />
            <TextField
              type="text"
              label="Username"
              placeholder="Choose wisely"
              value={userName}
              onChange={({ target: { value } }) => setUserName(value)}
              helperText={validationErrors["userName"]}
              error={Boolean(validationErrors["userName"])}
              fullWidth
            />
            <TextField
              type="text"
              label="Your full name"
              placeholder="John Johnson"
              value={name}
              onChange={({ target: { value } }) => setName(value)}
              helperText={validationErrors["name"]}
              error={Boolean(validationErrors["name"])}
              fullWidth
            />

            <Button
              color="primary"
              type="submit"
              data-loading={isValidating}
              variant="contained"
              size="large"
            >
              Sign Up
            </Button>

            <div>
              Already got an account?{" "}
              <Link to="/auth" style={{ color: "#fff" }}>
                Sign in
              </Link>
            </div>
          </Stack>
        </form>
      </article>
    </main>
  );
}
