import { Stack, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { post } from "../utils/api";

type Exists<T> = T extends null | undefined ? never : T;
type ExternalLocation = Awaited<
  ReturnType<typeof post<"/extlocationlookup">>
>["locations"][0];
type Location = Exists<Parameters<typeof post<"/show">>[1]["location"]>;

const LOCATION_LABELS: Record<keyof Location, string> = {
  venueName: "Venue name",
  box: "Box",
  city: "City",
  country: "Country",
  latitude: "Latitude",
  longitude: "Longitude",
  streetName: "Street",
  streetNumber: "Street number",
};

interface Props {
  location?: Location;
  onChangeLocation: (value: Location) => void;
  errorMessages?: Record<keyof Location, string>;
}

export function LocationInput({
  location,
  onChangeLocation,
  errorMessages,
}: Props) {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState<ExternalLocation[]>([]);

  const search = useCallback(async (query: string) => {
    const { locations } = await post("/extlocationlookup", { query });

    console.log({ locations });

    if (locations && locations.length) {
      setSuggestions(locations);
    }
  }, []);

  const handleClick = useCallback(
    (suggestion: ExternalLocation) => {
      onChangeLocation({
        streetName: suggestion.address?.road,
        box: "",
        city: suggestion.address?.city,
        country: suggestion.address?.country,
        latitude: Number(suggestion.lat),
        longitude: Number(suggestion.lon),
        streetNumber: suggestion.address?.house_number,
        venueName: suggestion.name,
      });
    },
    [onChangeLocation]
  );

  const searchDebounced = useDebouncedCallback(search, 1000);

  const renderLocationDetailInput = useCallback(
    (key: keyof Location) => {
      if (!location) {
        return null;
      }

      return (
        <TextField
          name={`location.${key}`}
          value={String(location[key] ?? "")}
          onChange={({ target: { value } }) =>
            onChangeLocation({ ...location, [key]: value })
          }
          aria-errormessage={errorMessages?.[key]}
          label={LOCATION_LABELS[key]}
          fullWidth
        />
      );
    },
    [errorMessages, location, onChangeLocation]
  );

  useEffect(() => {
    searchDebounced(query);
  }, [query, search, searchDebounced]);

  return (
    <div>
      {location ? (
        <Stack spacing={2}>
          {renderLocationDetailInput("venueName")}
          {renderLocationDetailInput("streetName")}
          {renderLocationDetailInput("streetNumber")}
          {renderLocationDetailInput("box")}
          {renderLocationDetailInput("city")}
          {renderLocationDetailInput("country")}
        </Stack>
      ) : (
        <>
          <TextField
            type="location"
            value={query}
            onChange={({ target: { value } }) => setQuery(value)}
            placeholder="Search for location..."
            fullWidth
          />
          <ul>
            {suggestions.map((suggestion) => (
              <li
                key={suggestion.osm_id}
                onClick={() => handleClick(suggestion)}
                role="button"
                tabIndex={0}
              >
                <strong>{suggestion.name}</strong>
                <span>
                  {suggestion?.address?.road}{" "}
                  {suggestion?.address?.house_number},{" "}
                  {suggestion?.address?.city}
                </span>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
