/* eslint-disable jsx-a11y/heading-has-content */
export const H1 = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >
) => (
  <h1
    {...props}
    className="text-3xl font-medium text-center"
    style={{ fontSize: "3rem" }}
  />
);
