import { create } from "zustand";
import { ReadWriteState } from "./helpers/readWriteState";
import { getItem, setItem } from "./helpers/storage";

export interface AuthState {
  token: string | null;
  userId: string | null;
}
export const initialState: AuthState = { token: null, userId: null };

export const useAuth = create<ReadWriteState<AuthState>>((set) => ({
  token: null,
  userId: null,
  setToken: (token: AuthState["token"]) =>
    set((state) => ({ ...state, token })),
  setUserId: (userId: AuthState["userId"]) =>
    set((state) => ({ ...state, userId })),
}));

const localUserId = getItem("auth.userId");
const localToken = getItem("auth.token");
useAuth.setState({ userId: localUserId, token: localToken });

// Todo: cookie banner
useAuth.subscribe(async ({ token, userId }) => {
  if (userId) {
    setItem("auth.userId", userId);
  }
  if (token) {
    setItem("auth.token", token);
  }
});
